<template>
  <section class="news-main flex1">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain'
}
</script>
<style lang="less" scoped>
  .news-main {
    // padding: 20px 0 5px 32px;
    background-color: #fafafa;
  }

</style>
